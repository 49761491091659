@import './utils/variables';

body{
    font-family: 'Poppins';
}
p{
    margin: 0;
}
input{
    font-size: $FONT_SIZE_12 !important;
}
::placeholder {
    color: $COLOR_BLACK_3;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $COLOR_BLACK_3;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: $COLOR_BLACK_3;
}

::-webkit-scrollbar {
    width: 2px;
    height: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $COLOR_GRAY_7; 
    border-radius: 3px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $COLOR_GRAY_3; 
  }