//add color variables

$COLOR_WHITE: #fff;
$COLOR_WHITE_2: #fcfcfc;

$COLOR_GREEN_1: #0aab83;
$COLOR_GREEN_2: #3ab496;
$COLOR_GREEN_3: #71c15f;
$COLOR_GREEN_4: #30cb52;
$COLOR_GREEN_5: #a3b43a;
$COLOR_GREEN_6: #589c2e;
$COLOR_GREEN_7: #d2fbf0;
$COLOR_GREEN_8: #dbeab0;
$COLOR_GREEN_9: #bcd4ad;

$COLOR_BLUE_1: #71b7e6;
$COLOR_BLUE_2: #69a6ce;
$COLOR_BLUE_3: #3b60e4;
$COLOR_BLUE_4: #0099cf;
$COLOR_BLUE_5: #ebfaff;
$COLOR_BLUE_6: #4cd4f1;
$COLOR_BLUE_7: #55baf2;
$COLOR_BLUE_8: #6a85eb;
$COLOR_BLUE_9: #6acbea;
$COLOR_BLUE_10: #e9f9fd;
$COLOR_BLUE_11: #f6f7fe;
$COLOR_BLUE_12: #edf0fd;
$COLOR_BLUE_13: #dcf1f8;
$COLOR_BLUE_14: #e5e9ff;
$COLOR_BLUE_15: #2a49b6;
$COLOR_BLUE_16: #6a85eb1a;
$COLOR_BLUE_17: #2789e3;
$GOOGLE_BLUE: #4285f4;

$COLOR_PURPLE_1: #522882;
$COLOR_PURPLE_2: #a029a3;
$COLOR_PURPLE_3: #b98acc;
$COLOR_PURPLE_4: #9b59b6;
$COLOR_PURPLE_5: #8268ed;
$COLOR_PURPLE_6: #c16aea;
$COLOR_PURPLE_7: #f4effa;
$COLOR_PURPLE_8: #f0edfd;

$COLOR_PINK_1: #de0097;
$COLOR_PINK_2: #ff5adb;
$COLOR_PINK_3: #ffebfb;
$COLOR_PINK_4: #f9e1f4;

$COLOR_YELLOW_1: #f4bf03;
$COLOR_YELLOW_2: #d8a31c;

$COLOR_ORANGE_1: #ff4d00;
$COLOR_ORANGE_2: #ff8326;
$COLOR_ORANGE_3: #f9e7da;
$COLOR_ORANGE_4: #fff1eb;
$COLOR_ORANGE_5: #fff0e5;

$COLOR_RED_1: #fb2e2e;
$COLOR_RED_2: #ee8176;
$COLOR_RED_3: #ea6a6a;
$COLOR_RED_4: #dc3545;
$COLOR_RED_5: #DD6767;

$COLOR_BROWN_1: #b43a3a;
$COLOR_BROWN_2: #985b41;
$COLOR_BROWN_3: #f9f3f1;

$COLOR_GRAY_1: #666666;
$COLOR_GRAY_2: #9a9a9a;
$COLOR_GRAY_3: #bfbfbf;
$COLOR_GRAY_4: #f4f8fe;
$COLOR_GRAY_5: #e0e0e0;
$COLOR_GRAY_6: #eeeeee;
$COLOR_GRAY_7: #a1a1a1;
$COLOR_GRAY_8: #858585;
$COLOR_GRAY_9: #e8ecfc;
$COLOR_GRAY_10: #a7a7a7;

$COLOR_BLACK_1: #000000;
$COLOR_BLACK_2: #3a3a3a;
$COLOR_BLACK_3: #797979;
$COLOR_BLACK_4: #525252;

$COLOR_RED_1: #ee8176;
$COLOR_RED_2: #ff0000;
$COLOR_RED_3: #ff4d00;
$COLOR_RED_4: #b94d4d;

$COLOR_GRAY_SHADOW: #0000001a;
$COLOR_GRAY_SHADOW_2: #00000080;
$COLOR_GRAY_SHADOW_3: #edf0fd80;
$COLOR_GRAY_SHADOW_4: #e0e0e080;
$COLOR_TRANSPERANT: #0000;

//add fonts variables

$FONT_SIZE_8: 8px;
$FONT_SIZE_10: 10px;
$FONT_SIZE_11: 11px;
$FONT_SIZE_12: 12px;
$FONT_SIZE_13: 13px;
$FONT_SIZE_14: 14px;
$FONT_SIZE_15: 15px;
$FONT_SIZE_16: 16px;
$FONT_SIZE_17: 17px;
$FONT_SIZE_18: 18px;
$FONT_SIZE_19: 19px;
$FONT_SIZE_20: 20px;
$FONT_SIZE_21: 21px;
$FONT_SIZE_22: 22px;
$FONT_SIZE_23: 23px;
$FONT_SIZE_24: 24px;
$FONT_SIZE_25: 25px;
$FONT_SIZE_40: 40px;
$FONT_SIZE_50: 50px;
$FONT_SIZE_150: 150px;

// add responsive size variables
$breakpoint_tablet: 768px;
$breakpoint_mobile_1: 425px;
$breakpoint_mobile_2: 350px;
