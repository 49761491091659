@import '../../utils/variables';

.mainDiv {
  height: 100vh;
  position: relative;
  background: -webkit-repeating-linear-gradient(-45deg, $COLOR_BLUE_1, $COLOR_BLUE_2, $COLOR_PURPLE_1, $COLOR_RED_1, $COLOR_PURPLE_1, $COLOR_BLUE_2, $COLOR_PURPLE_2);
  background-size: 400%;

  .errorPage {
    position: absolute;
    top: 10%;
    left: 15%;
    right: 15%;
    bottom: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $COLOR_WHITE;
    box-shadow: $COLOR_GRAY_SHADOW;

    .content {
      max-width: 600px;
      text-align: center;

      .header {
        font-size: $FONT_SIZE_150;
        position: relative;
      }

      .btns {
        margin: 25px 0;
        display: inline-flex;
      }

      .returnBtn {
        display: inline-block;
        margin: 0 10px;
        text-decoration: none;
        border: 2px solid $COLOR_BLUE_2;
        color: $COLOR_BLUE_2;
        font-weight: 500;
        padding: 10px 25px;
        border-radius: 25px;
        text-transform: uppercase;
        transition: all 0.3s ease;

        &:hover {
          background: $COLOR_BLUE_2;
          color: $COLOR_WHITE;
        }
      }

    }
  }
}